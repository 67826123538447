export function checkResponse(res) {
  if (res.data.error_code) {
    throw new Error(res.data.error_code);
  } else {
    window.axios.defaults.headers.common["X-Client-Token"] = res.headers["x-client-token"];

    return res.data;
  }
}

export function createPhoto(image, data) {
  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(checkResponse);
}

export function fetchPhoto(id) {
  return window.axios.get(window.appConfig.paths.api + "/photos/" + id).then(checkResponse);
}

export function createCollage(photoId, creatives) {
  return window.axios.post(window.appConfig.paths.api + "/collages/create", {
    "photo_id": photoId,
    creatives
  }).then(checkResponse);
}

export function fetchCollage(hash) {
  return window.axios.get(window.appConfig.paths.api + "/collages/" + hash).then(checkResponse);
}

export function logCollageShare(id, provider) {
  return window.axios.post(window.appConfig.paths.api + "/collages/log-share", {id, provider}).then(checkResponse);
}
