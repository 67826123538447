import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";

const FETCH_INTERVAL = 1000;

const PHOTO_STATUS_FAILED = -1;
const PHOTO_STATUS_PROCESSED = 1;

const COLLAGE_STATUS_FAILED = -1;
const COLLAGE_STATUS_PROCESSED = 1;

const CREATIVE_STATUS_FAILED = -1;
const CREATIVE_STATUS_PROCESSED = 2;

const creativeIsFailed = (creative) => creative.status === CREATIVE_STATUS_FAILED;
const creativeIsProcessed = (creative) => creative.status === CREATIVE_STATUS_PROCESSED;
const creativeIsProcessing = (creative) => !(creativeIsProcessed(creative) || creativeIsFailed(creative));

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photo: null,
      collage: null,
    };

    this.createPhoto = this.createPhoto.bind(this);
    this.fetchPhoto = this.fetchPhoto.bind(this);
    this.handlePhotoStatus = this.handlePhotoStatus.bind(this);
    this.fetchCollage = this.fetchCollage.bind(this);
    this.handleCollageStatus = this.handleCollageStatus.bind(this);
    this.debugCreatives = this.debugCreatives.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createPhoto(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createPhoto(file) {
    api.createPhoto(file)
      .then((res) => {
        hitEvent(hits.PHOTO_UPLOADED);
        logEvent(userEvents.PHOTO_UPLOADED);
        this.handlePhotoStatus(res);
      })
      .catch((err) => {
        hitEvent(hits.PHOTO_UPLOAD_FAILED);
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  fetchPhoto(photoId) {
    api.fetchPhoto(photoId)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handlePhotoStatus(res) {
    if (res.photo.status === PHOTO_STATUS_PROCESSED) {
      hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED);

      this.debugCreatives(res.creatives);

      api.createCollage(res.photo.id, res.creatives.map(c => c.id))
        .then(this.handleCollageStatus)
        .catch((err) => {
          console.error(err);
          this.props.history.replace(routes.ERROR);
        });

      return;
    }

    if (res.photo.status === PHOTO_STATUS_FAILED) {
      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED);

      this.debugCreatives(res.creatives);

      let errorMessage;
      if (res.photo.crop_task_response && res.photo.crop_task_response.description) {
        errorMessage = res.photo.crop_task_response.description;
      } else {
        const failedCreative = res.creatives.find(creativeIsFailed);
        if (failedCreative) {
          errorMessage = failedCreative.error;
        }
      }

      this.props.history.replace({
        pathname: routes.ERROR,
        state: {message: errorMessage}
      });
      return;
    }

    this.setState({photo: res.photo});
    this.fetchTimer = setTimeout(() => this.fetchPhoto(res.photo.id), FETCH_INTERVAL);
  }

  fetchCollage(collageHash) {
    api.fetchCollage(collageHash)
      .then(this.handleCollageStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handleCollageStatus(res) {
    if (res.collage.status === COLLAGE_STATUS_PROCESSED) {
      this.props.history.replace({
        pathname: generatePath(routes.COLLAGE, {hash: res.collage.hash}),
        state: {collage: res.collage}
      });
      return;
    }

    if (res.collage.status === COLLAGE_STATUS_FAILED) {
      this.props.history.replace(routes.ERROR);
      return;
    }

    this.setState({collage: res.collage});
    this.fetchTimer = setTimeout(() => this.fetchCollage(res.collage.hash), FETCH_INTERVAL);
  }

  debugCreatives(creatives) {
    if (!window.appConfig.isDebug) {
      return;
    }

    creatives
      .sort((c1, c2) => c1.status > c2.status ? -1 : (c1.status === c2.status ? 0 : 1))
      .forEach((creative) => {
        const isFailed = creativeIsFailed(creative);
        const c = {
          name: creative.name,
          time: creative.processed_time,
        };

        if (isFailed) {
          c.error = creative.error;
        }

        console[isFailed ? "warn" : "info"](c);
      });
  }

  render() {
    return <Loading image={this.state.photo && this.state.photo.file.url} />;
  }
}

CreatePage.contextType = AppContext;