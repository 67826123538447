import * as amplitude from "amplitude-js";

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({
    is_webview: window.clientConfig.isWebview,
  });
}

// --

export const userEvents = {
  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу результата
  // параметры: is_owner (владелец/реферал), ref (канал шаринга или unspecified)
  PAGE_COLLAGE: "page_collage",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // успешно закончился процессинг всех обработок
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  PROCESSING_FAILED: "processing_failed",

  // коллаж успешно сгенерировался
  COLLAGE_PROCESSED: "collage_processed",

  // коллаж не сгенерировался
  COLLAGE_FAILED: "collage_failed",

  // юзер кликнул на кнопку шаринга
  // параметры: id (наш идентификатор коллажа), provider (название поток шаринга)
  COLLAGE_SHARE: "collage_share",
};

export const hits = {
  TAB_OPENED: 0,

  PHOTO_SELECT: 0,
  PHOTO_UPLOADED: 0,
  PHOTO_UPLOAD_FAILED: 0,

  PROCESSING_PROCESSED: 0,
  PROCESSING_FAILED: 0,

  COLLAGE_PROCESSED: 0,
  COLLAGE_FAILED: 0,

  COLLAGE_SHARE: 8107,
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  logAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    window.axios.post(window.appConfig.analytics.endpoint, {id: eventId, params: eventParams})
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}