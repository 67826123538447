import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import HomeButton from "../components/HomeButton";
import FileChooseButton from "../components/FileChooseButton";
import Share from "../components/Share";
import AppContext from "../contexts/AppContext";
import {getLocationQueryObject} from "../utils/text";

const COLLAGE_STATUS_FAILED = -1;
const COLLAGE_STATUS_PROCESSED = 1;

const FETCH_INTERVAL = 1000;

export default class CollagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collage: null,
    };

    this.fetchCollage = this.fetchCollage.bind(this);
    this.handleCollageStatus = this.handleCollageStatus.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.collage) {
      this.handleCollageStatus(this.props.location.state.collage);
    } else {
      this.fetchCollage();
    }
  }

  fetchCollage() {
    api.fetchCollage(this.props.match.params.hash)
      .then((res) => this.handleCollageStatus(res.collage))
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handleCollageStatus(collage) {
    let isLoading = false;
    let cb = undefined;

    if (collage.status === COLLAGE_STATUS_FAILED) {
      hitEvent(hits.COLLAGE_FAILED);
      logEvent(userEvents.COLLAGE_FAILED);

      this.props.history.replace(routes.ERROR);
      return;
    }

    if (collage.status === COLLAGE_STATUS_PROCESSED) {
      hitEvent(hits.COLLAGE_PROCESSED);
      logEvent(userEvents.COLLAGE_PROCESSED);
    } else {
      isLoading = true;
      cb = () => {
        setTimeout(this.fetchCollage, FETCH_INTERVAL);
      };
    }

    if (this.state.collage === null || this.state.collage.id !== collage.id) {
      logEvent(userEvents.PAGE_COLLAGE, {
        is_owner: collage.is_owner,
        ref: collage.is_owner ? undefined : (getLocationQueryObject().ref || "unspecified"),
      });
    }

    this.setState({isLoading, collage}, cb);
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});
    hitEvent(hits.PHOTO_SELECT);

    this.props.history.push(routes.CREATE, {file});
  }

  render() {
    if (this.state.isLoading) {
      const photo = this.props.location.state
        && this.props.location.state.photo
        && this.props.location.state.photo.file
        && this.props.location.state.photo.file.url;

      return <Loading image={photo} />;
    }

    const isOwner = this.state.collage.is_owner;
    const isGuest = !isOwner;

    return <main className="collage-page">
      <div className="container">
        <HomeButton hidden={isGuest} page="result" />
        
        <h1 hidden={isOwner}
          className="main-title"
          dangerouslySetInnerHTML={{__html: i18n.t("landing__title")}} />

        <h2 hidden={isGuest}>{i18n.t("collage__title")}</h2>

        <img src={this.state.collage.file.url} alt="Collage" />

        <Share
          hidden={isGuest}
          collage={this.state.collage} />

        <div hidden={isOwner}>
          <p className="collage-page-text" dangerouslySetInnerHTML={{__html: i18n.t("collage__text_guest")}} />
          <FileChooseButton
            onFileSelected={this.handleFileSelected}
            className="btn-upload-foto"
            children={i18n.t("landing__select_photo_button")} />
        </div>

      </div>
    </main>;
  }
}

CollagePage.contextType = AppContext;