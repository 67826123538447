module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",
    "go_back": "Go Back",

    "landing__title": "Creepy Artist<br /><span>The F%cked up Neural Network</span>",
    "landing__subtitle": "This AI went off the rails. Upload your photo for some creepy shit.",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Upload photo",

    "processing_text_0": "This artist has serious issues! 🤪",
    "processing_text_1": "“Draw different” is his credo... 🤓",
    "processing_text_2": "Ready to meet your inner weirdo? 👽",
    "processing_text_3": "Perfect is the synonym of boring... 💩",
    "processing_text_4": "Creepy things are the coolest, right? 🧟",
    "processing_text_5": "You’re gonna get your best portrait ever! But that is not for sure 😜",
    "processing_text_6": "Get ready to be amazed! Or scared, who knows 🤔",
    "processing_text_7": "Ready to reveal your dark side? 🙀",
    "processing_text_8": "This artist will show you the dark side of things 🧟‍♀️",
    "processing_text_9": "Don’t shoot the artist, he’s doing his best 🤪",
    "processing_text_10": "You never know how creepy you can look 👻",

    "create__step_headline_1": "Tap the result you like most to proceed",
    "create__step_headline_2": "Select one more result...",
    "create__step_headline_3": "And the last one!",
    "create__step_counter": "{{current}} out of {{total}}",

    "create__select_one_result": "Please select the result you like most and tap 'Next' to proceed",
    "create__select_two_results": "Now select two results you like best to create your collage",
    "create__next_button": "Next",
    "create__create_collage_button": "Create collage",

    "collage__title": "Save and share",
    "collage__text_guest": "This AI Artist went off the rails! Upload a photo for your own doze of creepiness.",

    "error__error": "Error",

    "internal_error": "An error occurred...",
  },
};

