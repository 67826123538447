import React from "react";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";

const LOADING_TEXT_INTERVAL = 3000;
const MAX_INDEX = 10;

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imageIsLoaded: false,
      textIndex: Math.floor(Math.random() * MAX_INDEX),
    };

    this.updateTextIndex = this.updateTextIndex.bind(this);

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex() {
    let nextIndex = this.state.textIndex + 1;
    if (nextIndex > MAX_INDEX) {
      nextIndex = 0;
    }

    this.setState({textIndex: nextIndex});
  }

  render() {
    return <main className="loader">
      <div className="spinner-container">
        <div className="triple-spinner" />
        <img 
          srcSet={`${assetUrl("assets/img/icons/img-pl@2x.jpg")} 2x, ${assetUrl("assets/img/icons/img-pl@3x.jpg")} 3x`}
          src={assetUrl("assets/img/icons/img-pl.jpg")}
          alt="logo" />

        {this.props.image && this.state.imageIsLoaded && <img
          className="avatar-image"
          src={this.props.image}
          alt="" />}
      </div>
      <p dangerouslySetInnerHTML={{__html: i18n.t(`processing_text_${this.state.textIndex}`) || ""}} />
    </main>;
  }
}
